@tailwind base;
@tailwind components;
@tailwind utilities;


/* Marker Cluster */
.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6)
}

.marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6)
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6)
}

.marker-cluster-medium div {
    background-color: rgba(240, 194, 12, 0.6)
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6)
}

.marker-cluster-large div {
    background-color: rgba(241, 128, 23, 0.6)
}

.leaflet-oldie .marker-cluster-small {
    background-color: #b5e28c
}

.leaflet-oldie .marker-cluster-small div {
    background-color: #6ecc39
}

.leaflet-oldie .marker-cluster-medium {
    background-color: #f1d357
}

.leaflet-oldie .marker-cluster-medium div {
    background-color: #f0c20c
}

.leaflet-oldie .marker-cluster-large {
    background-color: #fd9c73
}

.leaflet-oldie .marker-cluster-large div {
    background-color: #f18017
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif
}

.marker-cluster span {
    line-height: 30px
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
    -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
    -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in
}

.leaflet-cluster-spider-leg {
    -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
    -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
    -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
    transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in
}

.error {
    color: red;
}

.Slider {
    width: 100%;
    height: 10px;
    background-color: #cdd9ed;
}

.Slider .thumb {
    width: 23px;
    height: 23px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #275efe;
    margin-top: -5px;
}

.horizontal-slider {
    width: 100%;
    max-width: 500px;
    height: 100vh;
    margin: auto;
    border: 1px red solid;
  }
  .example-thumb {
    /* cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 5px solid #3774ff;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%); */
    cursor: pointer;
    border : 1px green solid;
    padding : 10px;
    position: absolute;
    top : -100;
  }
  .example-thumb.active {
    /* background-color: grey; */
  }
  .example-track {
    position: relative;
    background: #ddd;
  }
  .example-track.example-track-0 {
    background: #83a9ff;
  }
  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }
  .horizontal-slider .example-thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }


.list {
    user-select: none;
    display: flex;
    justify-content: flex-start;
  }
  
  .item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(84, 84, 241);
    color: white;
    margin: 8px;
    width: 60px;
    height: 60px;
    cursor: grab;
  }
  
  .dragged {
    background-color: rgb(37, 37, 197);
  }
  
  .custom-dropdown{
    position: absolute;
    margin: 0px;
    top: 100%;
  }
  .leaflet-container{
    z-index: 0;
  }
  .download{
    height: 100vh;
    width: 100%;
    top: 0;
    z-index: 99;
  }

  .bg-success\/10 {
    background-color: rgba(16, 185, 129, 0.1);
  }
  .text-success {
    color: #10b981;
  }
  .bg-error\/10 {
    background-color: rgba(255, 87, 36, 0.1);
  }
  .text-error {
    color: #ff5724;
  }

  .spinner.is-elastic {
    animation-duration: 1.3;
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
  }

  .address-max{
    max-width: 120px;
  }

  .custom-font {
    font-family: 'Lexend Deca Variable', sans-serif;
  }